<template>
  <div class="px-4 py-5 border-b border-gray-200 bg-white rounded-t-md sm:px-6 shadow">
    <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
      <div class="ml-4 mt-2">
        <h3 class="text-lg leading-6 font-bold text-gray-900">
          <slot />
        </h3>
      </div>
      <div class="ml-4 mt-2 flex-shrink-0">
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script setup>
</script>
