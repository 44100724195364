<template>
  <div class="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
    <!-- Header -->
    <div class="bg-blue-700 px-4 py-6 sm:px-6">
      <div class="flex items-center justify-between">
        <h2 class="text-lg font-medium text-white" id="slide-over-title">
          Ajouter des permissions au rôle
        </h2>
      </div>
      <div class="mt-1">
        <p class="text-sm text-blue-200">
          Rôle: {{ role?.name }}
        </p>
      </div>
    </div>

    <!-- Search and Selection Area -->
    <div class="relative flex-1 px-4 sm:px-6">
      <!-- Search input -->
      <div class="sticky top-0 z-10 bg-white pt-4 pb-2">
        <div class="relative">
          <input v-model="searchQuery" type="text"
            class="block w-full rounded-md border-gray-300 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:ring-blue-500"
            placeholder="Rechercher des permissions...">
          <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <IconSearch class="h-5 w-5 text-gray-400" />
          </div>
        </div>

        <!-- Selected count -->
        <div v-if="selectedPermissions.length" class="mt-2 flex items-center justify-between">
          <span class="text-sm text-gray-600">
            {{ selectedPermissions.length }} sélectionné(s)
          </span>
          <button @click="selectedPermissions = []" class="text-sm text-blue-600 hover:text-blue-800">
            Tout désélectionner
          </button>
        </div>
      </div>

      <!-- Permissions list -->
      <div class="mt-4 space-y-2">
        <TransitionGroup name="list">
          <div v-for="permission in filteredPermissions" :key="permission.id"
            class="group relative flex items-center space-x-3 rounded-lg border px-5 py-4 shadow-sm hover:border-blue-400 transition-colors"
            :class="[
              isSelected(permission)
                ? 'border-blue-500 bg-blue-50'
                : 'border-gray-200 bg-white'
            ]">
            <div class="flex-1 min-w-0">
              <button class="focus:outline-none w-full text-left" @click="togglePermission(permission)">
                <span class="absolute inset-0" aria-hidden="true" />
                <p class="text-sm font-medium text-gray-900">
                  {{ permission.name }}
                </p>
                <p class="text-sm text-gray-500 truncate">
                  {{ permission.description || 'Aucune description' }}
                </p>
              </button>
            </div>
            <div class="flex-shrink-0">
              <IconCheck v-if="isSelected(permission)" class="h-5 w-5 text-blue-500" />
            </div>
          </div>
        </TransitionGroup>

        <!-- Empty state -->
        <div v-if="filteredPermissions.length === 0" class="text-center py-12">
          <IconSearch class="mx-auto h-12 w-12 text-gray-400" />
          <h3 class="mt-2 text-sm font-medium text-gray-900">
            Aucune permission trouvée
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Essayez avec d'autres termes de recherche
          </p>
        </div>
      </div>
    </div>

    <!-- Action buttons -->
    <div class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
      <div class="flex justify-end space-x-3">
        <button type="button"
          class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          @click="closeModal">
          Annuler
        </button>
        <button type="button"
          class="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          :disabled="selectedPermissions.length === 0 || loading" @click="savePermissions">
          <ProgressSpinner v-if="loading" style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
            animationDuration=".5s" aria-label="Loading spinner" />
          Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, type PropType } from 'vue'
import IconSearch from '~/assets/icons/search.svg'
import IconCheck from '~/assets/icons/check-circle.svg'
import type { BasicPermissionInfo, RoleWithPermissions } from "~/types/Role"
const props = defineProps({
  role: {
    type: Object as PropType<RoleWithPermissions>,
    required: true
  }
})

const emit = defineEmits(['close', 'permissions-updated'])
const roleService = useRoleService()
const availablePermissions = ref<BasicPermissionInfo[]>([])
const selectedPermissions = ref([])
const searchQuery = ref('')
const loading = ref(false)
const { $eventBus } = useNuxtApp();

const filteredPermissions = computed(() => {
  if (!searchQuery.value) return availablePermissions.value

  const query = searchQuery.value.toLowerCase()
  return availablePermissions.value.filter(permission =>
    permission.name.toLowerCase().includes(query))
})

onMounted(async () => {
  try {
    availablePermissions.value = await roleService.getAvailablePermissions(props.role.id);
  } catch (error) {
    console.error('Error loading data:', error)
    $eventBus.emit("toast", { severity: "error", summary: 'Erreur lors du chargement des données', life: 2000 })
    emit('close')
  }
})

onMounted(() => {
  document.addEventListener('keydown', handleKeydown)
})
onUnmounted(() => {
  document.removeEventListener('keydown', handleKeydown)
})
const togglePermission = (permission) => {
  const index = selectedPermissions.value.findIndex(p => p.id === permission.id)
  if (index === -1) {
    selectedPermissions.value.push(permission)
  } else {
    selectedPermissions.value.splice(index, 1)
  }
}

const savePermissions = async () => {
  if (selectedPermissions.value.length === 0) return

  loading.value = true
  try {
    await roleService.addPermissionsToRole(
      props.role.id,
      selectedPermissions.value.map(p => p.id)
    )
    $eventBus.emit("toast", { severity: "success", summary: 'Permissions ajoutées avec succès', life: 2000 })
    emit('permissions-updated')
    emit('close')
  } catch (error) {
    console.error('Error adding permissions:', error)
    $eventBus.emit("toast", { severity: "error", summary: 'Erreur lors de l\'ajout des permissions', life: 2000 })
  } finally {
    loading.value = false
  }
}

const isSelected = (permission) => {
  return selectedPermissions.value.some(p => p.id === permission.id)
}

const closeModal = () => {
  emit('close')
}

// Optional: Keyboard handling
const handleKeydown = (event) => {
  if (event.key === 'Escape') {
    closeModal()
  }
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
