<template>
  <div class="p-6 space-y-4">
    <!-- Header with role info -->
    <div class="flex items-center justify-between mb-6">
      <div>
        <h1 class="text-2xl font-bold text-gray-900">Permissions du rôle</h1>
        <p class="text-sm text-gray-600 mt-1">
          Gérer les permissions pour le rôle "{{ role.name }}"
        </p>
      </div>
      <div class="flex items-center space-x-2">
        <span class="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
          {{ role.permissions?.length || 0 }} permissions
        </span>
      </div>
    </div>

    <!-- Search bar -->
    <div class="relative">
      <input v-model="searchQuery" type="text" placeholder="Rechercher des permissions..."
        class="w-full px-4 py-2 pl-10 pr-4 text-sm border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />
      <span class="absolute left-3 top-2.5 text-gray-400">
        <IconSearch class="w-5 h-5" />
      </span>
    </div>

    <!-- Main content -->
    <div class="mt-6">
      <!-- Empty state -->
      <div v-if="!role?.permissions?.length" class="text-center py-12">
        <div class="mx-auto w-24 h-24 text-gray-400 mb-4">
          <IconPermissions class="w-full h-full" />
        </div>
        <h3 class="text-lg font-medium text-gray-900 mb-2">Aucune permission</h3>
        <p class="text-sm text-gray-500">
          Ce rôle n'a pas encore de permissions attribuées.
        </p>
      </div>

      <!-- Permissions grid -->
      <div v-else>
        <!-- Grouped by category -->
        <div v-for="(group, category) in groupedPermissions" :key="category" class="mb-6">
          <h3 class="text-sm font-medium text-gray-500 mb-3">{{ category }}</h3>
          <div class="grid gap-2">
            <div v-for="permission in group" :key="permission.id"
              class="group flex items-center justify-between p-3 bg-white border border-gray-200 rounded-lg hover:border-blue-300 transition-all duration-200"
              :class="{ 'opacity-50': loading }">
              <div class="flex items-center space-x-3">
                <div class="flex-shrink-0">
                  <IconShield class="w-5 h-5 text-blue-500" />
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-900">
                    {{ permission.name }}
                  </p>
                  <p class="text-xs text-gray-500">
                    {{ permission.description }}
                  </p>
                </div>
              </div>

              <button @click="handlePermissionRemove(permission.id)" :disabled="loading"
                class="hidden group-hover:flex items-center px-3 py-1 text-sm text-red-600 bg-red-50 rounded-md hover:bg-red-100 transition-colors duration-200">
                <IconXmark class="w-4 h-4 mr-1" />
                Retirer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
      <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
    </div>

    <TransitionRoot appear :show="showConfirmDialog" as="template">
      <Dialog as="div" @close="showConfirmDialog = false" class="relative z-10">
        <TransitionChild enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4">
            <TransitionChild enter="ease-out duration-300" enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95">
              <DialogPanel class="w-full max-w-md p-6 bg-white rounded-lg shadow-xl">
                <DialogTitle class="text-lg font-medium text-gray-900">
                  Confirmer la suppression
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Êtes-vous sûr de vouloir retirer cette permission du rôle ? Cette action ne peut pas être annulée.
                  </p>
                </div>
                <div class="mt-4 flex justify-end space-x-3">
                  <button @click="showConfirmDialog = false"
                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
                    Annuler
                  </button>
                  <button @click="confirmRemovePermission"
                    class="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700">
                    Confirmer
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue'
import IconXmark from '~/assets/icons/x-mark.svg'
import IconSearch from '~/assets/icons/search.svg'
import IconPermissions from '~/assets/icons/shield-check.svg'
import IconShield from '~/assets/icons/shield-exclamation.svg'
const props = defineProps({
  role: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['permissions-updated'])
const roleService = useRoleService()
const searchQuery = ref('')
const showConfirmDialog = ref(false)
const permissionToRemove = ref(null)
const { $eventBus } = useNuxtApp()

const groupedPermissions = computed(() => {
  if (!props.role?.permissions) return {}

  const filtered = props.role.permissions.filter(permission =>
    permission.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  )

  return filtered.reduce((acc, permission) => {
    const category = permission.category || 'General'
    if (!acc[category]) acc[category] = []
    acc[category].push(permission)
    return acc
  }, {})
})

const handlePermissionRemove = (permissionId) => {
  permissionToRemove.value = permissionId
  showConfirmDialog.value = true
}

const confirmRemovePermission = async () => {
  showConfirmDialog.value = false
  try {
    await roleService.removePermissionsFromRole(
      props.role.id,
      [permissionToRemove.value])

    emit('permissions-updated', {
      roleId: props.role.id,
      permissionId: permissionToRemove.value,
      action: 'remove'
    })
    $eventBus.emit("toast", { severity: "success", summary: 'Permission retirée avec succès', life: 2000 });
  } catch (error) {
    console.error('Error removing permission:', error)
    $eventBus.emit("toast", { severity: "error", summary: 'Erreur lors de la suppression de la permission', life: 2000 });
  }

  permissionToRemove.value = null
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}
</style>
