<template>
  <section class="flex flex-col space-y-4 p-4">
    <div class="w-full flex space-x-4 flex-wrap gap-y-4">
      <div class="w-64 shrink-0">
        <Select v-model="selectedRoleId" @change="handleRoleChange" placeholder="Veuillez choisir un rôle"
          :options="roles" option-label="name" option-value="id" :class="{ 'opacity-50': loading }" :disabled="loading"
          class="w-full h-10" />
      </div>
      <div class="w-[clamp(12rem,fit,16rem)] shrink-0">
        <TransitionGroup name="fade" tag="div">
          <button v-if="selectedRoleId" key="add-permissions" type="button" @click="openAddPermissionsModal"
            :disabled="loading"
            class="w-full h-10 inline-flex items-center justify-center px-4 bg-blue-500 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-blue-700 active:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-300 disabled:opacity-25 transition duration-150 ease-in-out">
            <span v-if="!loading" class="truncate">Ajouter permissions</span>
            <span v-else class="flex items-center">
              <svg class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" ill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
              Chargement...
            </span>
          </button>
        </TransitionGroup>
      </div>
    </div>
    <Dialog as="div" v-model:visible="addPermissionsModalOpen" position="right" :modal="true" class="h-fit">
      <AddPermissionsToRole :role="selectedRole" @permissions-updated="handleRoleChange"
        @close="addPermissionsModalOpen = false" class="h-fit" />
    </Dialog>
    <Transition name="fade" mode="out-in">
      <EditRolePermissions v-if="selectedRole" key="edit-permissions" :role="selectedRole" :loading="loading"
        @permissions-updated="handleRoleChange" class="w-full" />
    </Transition>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import EditRolePermissions from "~/components/settings/EditRolePermissions.vue"
import AddPermissionsToRole from './AddPermissionsToRole.vue';
const roleService = useRoleService()
const roles = shallowRef<any[]>([])
const selectedRoleId = ref<number | null>(null)
const selectedRole = ref<any | null>(null)
const loading = ref(false)
const emit = defineEmits(['open-modal'])
const { $eventBus } = useNuxtApp();
const addPermissionsModalOpen = ref(false);
const loadRoles = async (): Promise<void> => {
  try {
    const resp = await roleService.getRoles();
    roles.value = resp?.data
    return Promise.resolve();
  } catch (error) {
    $eventBus.emit("toast", { severity: 'error', summary: 'Impossible de récuperer les roles', life: 2000 });
    console.error('Error loading roles:', error)
  }
}
const openAddPermissionsModal = async () => {
  addPermissionsModalOpen.value = true;

}
const handleRoleChange = async () => {
  if (!selectedRoleId.value) {
    selectedRole.value = null
    return
  }

  loading.value = true
  try {
    selectedRole.value = roles.value.find((role) => role.id == selectedRoleId.value);
    selectedRole.value.permissions = (await roleService.getPermissionsFromRole(selectedRoleId.value))?.data
  } catch (error) {

    $eventBus.emit("toast", { severity: 'error', summary: 'Impossible de récuperer les permissions', life: 2000 });
    console.error('Error fetching role:', error)
  } finally {
    loading.value = false
  }
}

onMounted(loadRoles)
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Prevent layout shifts during transitions */
.grid {
  min-height: 200px;
  /* Adjust based on your needs */
}
</style>
