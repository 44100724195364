import type { BasicPermissionInfo } from "~/types/Role";

export const useRoleService = function() {
  const apiFetch = useSanctumClient();
  return {
    getRoles: async function() {
      return apiFetch(apiUrl.roles);

    },
    getRole: async function(roleId: number) {
      return await apiFetch(buildUrl(apiUrl.getRole, { roleId }))
    },
    getPermissionsFromRole: async function(roleId: number) {
      return apiFetch(buildUrl(apiUrl.permissionsFromRole, { roleId }))
    },
    removePermissionsFromRole: async function(roleId: number, permissionsId: number[]) {
      return await apiFetch(buildUrl(apiUrl.removePermissionsFromRole, { roleId }), {
        method: 'DELETE',
        body: {
          resources: permissionsId
        }
      });
    },
    addPermissionsToRole: async function(roleId: number, permissionIds: number[]) {
      return await apiFetch(buildUrl(apiUrl.addPermissionsToRole, { roleId }), {
        method: "POST",
        body: {
          resources: permissionIds
        }
      })
    },
    createRole: async function(role: object) {
      return await apiFetch(apiUrl.createRole, {
        method: "POST",
        body: role
      });
    },
    updateRole: async function(roleId: number, modifiedRole: object) {
      return await apiFetch(buildUrl(apiUrl.updateRole, { roleId }), {
        method: "PUT",
        body: modifiedRole
      })
    },
    deleteRole: async function(roleId: number) {
      return await apiFetch(buildUrl(apiUrl.deleteRole, { roleId }), {
        method: "DELETE",
      })
    },
    getAvailablePermissions: async function(roleId: number): Promise<BasicPermissionInfo> {
      return await apiFetch(buildUrl(apiUrl.getAvailablePermissions, { roleId }));
    }
  }
}
