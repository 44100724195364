<template>
  <AtomsNavLink :navLinks="[{ route: '/settings/security', name: 'Sécurité' }]" />
  <div class="py-9 px-6">
    <Tabs value="0" :unstyled="true" :pt="{
      root: {
        class: 'flex gap-8 flex-1',
      },
    }">

      <TabList :pt="{
        tabList: {
          class: 'flex flex-col',
        },
      }">
        <Tab :pt="{
          root: (options) => ({
            class: [
              'text-gray-600 hover:bg-gray-50 hover:text-gray-900 flex items-center px-3 py-2 text-sm font-medium rounded-md', {
                'shadow bg-white text-gray-900': options.context.active
              }
            ]
          })
        }" v-for="tab, index in tabs" :key="tab" :value="index" v-text="tab" />
      </TabList>
      <TabPanels :pt="{
        root: {
          class: 'flex-1'
        }
      }">
        <TabPanel :value="0" v-slot="slotProps" as-child>

          <div v-show="slotProps.active" :class="slotProps.class" v-bind="slotProps.a11yAttrs"
            class="ml-5 bg-white rounded-md shadow">
            <SectionHeader>
              Rôles
              <template #action>
                <button @click="openCreateRoleModal"
                  class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Ajouter
                </button>
              </template>
            </SectionHeader>
            <LazyRoleTable v-if="slotProps.active" />
          </div>
        </TabPanel>
        <TabPanel :value="1" v-slot="slotProps" as-child>
          <div v-show="slotProps.active" :class="slotProps.class" v-bind="slotProps.a11yAttrs"
            class="ml-5 bg-white rounded-md shadow">
            <SectionHeader>
              Permissions
              <template #action>
                <button @click="openCreatePermissionModal"
                  class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Ajouter
                </button>
              </template>
            </SectionHeader>
            <LazyPermissionsTable v-if="slotProps.active" />

          </div>
        </TabPanel>
        <TabPanel :value="2" v-slot="slotProps" asChild>
          <div v-show="slotProps.active" :class="slotProps.class" v-bind="slotProps.a11yAttrs"
            class="ml-5 bg-white rounded-md shadow">
            <SectionHeader>
              Modifie les permissions pour un rôle
            </SectionHeader>
            <ManageRolePermissions v-if="slotProps.active" />
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script setup>
import SectionHeader from '~/components/atoms/SectionHeader.vue';
import ManageRolePermissions from '~/components/settings/ManageRolePermissions.vue'
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
const tabs = [
  "Rôles",
  "Permissions",
  "Change permissions"
]
const dialog = useDialog();
const openCreateRoleModal = () => {
  const CreateEditRoleComponent = defineAsyncComponent(() => import('~/components/settings/CreateEditRole.vue'));
  dialog.open(CreateEditRoleComponent, {
    props: {
      position: 'right',
      modal: true,
      dismissableMask: true,
      closeOnEscape: true,
      maximizable: true,
      style: {
        width: '32rem',
        height: '100vh',
        margin: '0',
        position: 'fixed',
        top: '0',
        right: '0'
      },
      contentStyle: {
        padding: '0',
        height: '100vh'
      },
      contentClass: 'p-0 h-full',
      class: 'p-dialog-right h-screen'
    },
  });

}

const openCreatePermissionModal = () => {
  const CreateEditPermissionComponent = defineAsyncComponent(() => import('~/components/settings/CreateEditPermission.vue'));
  dialog.open(CreateEditPermissionComponent, {
    props: {
      position: 'right',
      modal: true,
      dismissableMask: true,
      closeOnEscape: true,
      maximizable: true,
      style: {
        width: '32rem',
        height: '100vh',
        margin: '0',
        position: 'fixed',
        top: '0',
        right: '0'
      },
      contentStyle: {
        padding: '0',
        height: '100vh'
      },
      contentClass: 'p-0 h-full',
      class: 'p-dialog-right h-screen'
    },
  });

}
</script>
